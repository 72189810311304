import React, { useState, useEffect } from 'react';
import { MdChevronLeft, MdContentCopy } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import Header from '../../components/Header';
import {
  Container,
  Content,
  ContentForm,
  ContentInfo,
  HeaderModuleContent,
  Modal,
} from './styles';
import api from '../../services/api';
import Loading from '../../components/Loading';
import NotFound from '../../components/NotFound';
import iconWifiSmall from '../../assets/icon-wifi-small.png';
import iconWifi from '../../assets/icon-wifi.png';

const QRWifi = ({ moduleId }) => {
  const [wifi, setWifi] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { id, nickname } = useParams();
  const [activeModal, setActiveModal] = useState(false);

  const cardId = id || moduleId;

  useEffect(() => {
    try {
      api.get(`/qr-wifi/p/${cardId}`).then(response => {
        setWifi(response.data);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  function copyPassword() {
    copy(wifi.password);
    setActiveModal(true);
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const isEmbeded = query.get('isEmbeded') === 'true';

  return (
    <>
      <Header nickname={nickname} moduleId={cardId} />

      <Container isEmbeded={isEmbeded}>
        <HeaderModuleContent className="header-module-content">
          <Link to={`/user/${nickname}/?open_modules=true`} type="button">
            <MdChevronLeft size={40} color="#4F4F4F" />
          </Link>
          <div>
            <img src={iconWifiSmall} alt="" />
            <h3>Wi-fi</h3>
          </div>
        </HeaderModuleContent>

        {isLoading && <Loading />}

        {!isLoading && wifi && (
          <Content>
            <ContentInfo>
              <img src={iconWifi} alt="" />
              <span>Copie a senha para se conectar a rede Wi-Fi</span>
            </ContentInfo>

            <ContentForm>
              <span>Nome da Rede</span>
              <div>{wifi.network}</div>

              <span>Senha</span>
              <div>
                {wifi.password}

                <button type="button" onClick={copyPassword}>
                  <MdContentCopy size={22} color="#000" />
                </button>
              </div>
            </ContentForm>

            <button type="button" onClick={copyPassword}>
              <span>Copiar senha</span>
            </button>
          </Content>
        )}

        {!isLoading && !wifi && <NotFound message="QR Wifi não encontrado" />}

        {activeModal && (
          <Modal onClick={() => setActiveModal(false)}>
            <div>
              <span>A senha copiada com sucesso</span>
            </div>
          </Modal>
        )}
      </Container>
    </>
  );
};

export default QRWifi;
