import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Modules from '../Modules';
import QRCard from '../QRCard';
import QRAds from '../QRAds';
import QRDiscount from '../QRDiscount';
import QRFlyer from '../QRFlyer';
import QRGeneric from '../QRGeneric';
import QRMessage from '../QRMessage';
import QRPix from '../QRPix';
import QRWifi from '../QRWifi';
import api from '../../services/api';

const SelectModule = () => {
  const [moduleSelected, setModuleSelected] = useState({
    qr_name: 'default',
  });

  const { nickname } = useParams();

  useEffect(() => {
    api
      .get(`/module-select/p/${nickname}`)
      .then(response => setModuleSelected(response.data));
  }, []);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const getModuleSelected = useCallback(() => {
    switch (moduleSelected.qr_name) {
      case 'qr-card':
        return <QRCard moduleId={moduleSelected.qr_id} />;
      case 'qr-ads':
        return <QRAds moduleId={moduleSelected.qr_id} />;
      case 'qr-discount':
        return <QRDiscount moduleId={moduleSelected.qr_id} />;
      case 'qr-flyer':
        return <QRFlyer moduleId={moduleSelected.qr_id} />;
      case 'qr-generic':
        return <QRGeneric moduleId={moduleSelected.qr_id} />;
      case 'qr-message':
        return <QRMessage moduleId={moduleSelected.qr_id} />;
      case 'qr-pix':
        return <QRPix moduleId={moduleSelected.qr_id} />;
      case 'qr-wifi':
        return <QRWifi moduleId={moduleSelected.qr_id} />;
      default:
        return <Modules />;
    }
  }, [moduleSelected]);

  if (query.get('open_modules') === 'true') {
    return <Modules />;
  }

  return getModuleSelected();
};

export default SelectModule;
