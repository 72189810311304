import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import GlobalStyles from './styles/global';
import Routes from './routes';
import Footer from './components/Footer';
import api from './services/api';

function App() {
  const [backgroundColor, seBackgroundColor] = useState(null);
  useEffect(() => {
    const nickname = window.location.pathname.split('/')[2];
    api.get(`/qr-codes/${nickname}`).then(response => {
      if (response.data.user_configs) {
        seBackgroundColor(response.data.user_configs.background_color);
      }
    });
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes />
        <GlobalStyles backgroundColor={backgroundColor} />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
