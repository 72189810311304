/* eslint-disable consistent-return */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React, { useCallback, useEffect, useState } from 'react';
import { MdClose, MdShare } from 'react-icons/md';
import vCardsJS from 'vcards-js';
import FileSaver from 'file-saver';
import { IoPersonAddSharp } from 'react-icons/io5';
import { useLocation, useParams } from 'react-router-dom';
import { MobileView } from 'react-device-detect';

import tinycolor from 'tinycolor2';
import {
  AddContact,
  AddContactAlert,
  Container,
  ListModules,
  ModalAddContact,
  Partner,
  Profile,
  ShareProfile,
  SocialMedia,
} from './styles';
import api from '../../services/api';
import Loading from '../../components/Loading';
import iconInstagram from '../../assets/icon-instagram.png';
import iconFacebook from '../../assets/icon-facebook.png';
import iconTelegram from '../../assets/icon-telegram.png';
import iconWhatsapp from '../../assets/icon-whatsapp.png';
import iconYoutube from '../../assets/icon-youtube.png';
import iconTwitter from '../../assets/icon-twitter.png';
import iconEmail from '../../assets/icon-email.png';
import iconSite from '../../assets/icon-site.png';
import iconGoogleMaps from '../../assets/icon-google-maps.png';
import iconLinkedin from '../../assets/icon-linkedin.png';
import iconAppstore from '../../assets/icon-appstore.png';
import iconGoogleplay from '../../assets/icon-googleplay.png';
import iconBehance from '../../assets/icon-behance.png';
import ButtonModule from './components/ButtonModule';
import ExceededViews from './components/ExceededViews';

const Modules = () => {
  const [userModules, setUserModules] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userConfigs, setUserConfigs] = useState({
    module_dark_color: false,
    fonto_color: '#fff',
    primary_color: '#FF5C00',
    show_add_contact_message: false,
  });

  const [showAddContactAlert, setShowAddContactAlert] = useState(false);
  const [showAddContactButton, setShowAddContactButton] = useState(false);
  const [activeModalAddContact, setActiveModalAddContact] = useState(false);
  const [userDontHaveConfig, setUserDontHaveConfig] = useState(false);

  const { nickname } = useParams();

  useEffect(() => {
    try {
      api
        .get(`/qr-codes/${nickname}`)
        .then(response => {
          if (response.data.user_configs) {
            setUserConfigs({
              fonto_color: response.data.user_configs.font_color || '#fff',
              primary_color:
                response.data.user_configs.primary_color || '#FF5C00',
              module_dark_color: response.data.user_configs.module_dark_color,
              show_add_contact_message:
                response.data.user_configs.show_add_contact_message,
            });
          } else {
            setUserDontHaveConfig(true);
          }
          setUserModules(response.data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  const iphone = () => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') {
      return false;
    }

    return /iPhone/i.test(navigator.userAgent || navigator.vendor);
  };

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  useEffect(() => {
    if (userDontHaveConfig || userConfigs.show_add_contact_message) {
      setTimeout(() => {
        setShowAddContactAlert(true);
      }, 2000);

      setTimeout(() => {
        setShowAddContactAlert(false);
        setShowAddContactButton(true);
      }, 7000);
    }
  }, [userModules, userConfigs, userDontHaveConfig]);

  function getSocialMediaIcon(socialName) {
    switch (socialName) {
      case 'whatsapp':
        return iconWhatsapp;
      case 'instagram':
        return iconInstagram;
      case 'facebook':
        return iconFacebook;
      case 'telegram':
        return iconTelegram;
      case 'youtube':
        return iconYoutube;
      case 'twitter':
        return iconTwitter;
      case 'linkedin':
        return iconLinkedin;
      case 'site':
        return iconSite;
      case 'email':
        return iconEmail;
      case 'google_maps':
        return iconGoogleMaps;
      case 'appstore':
        return iconAppstore;
      case 'googleplay':
        return iconGoogleplay;
      case 'behance':
        return iconBehance;
      default:
        return '';
    }
  }

  const saveContact = useCallback(() => {
    const { name, phone_number } = userModules;
    const vCard = vCardsJS();
    vCard.firstName = name || '';
    vCard.cellPhone = phone_number || '';
    vCard.photo.embedFromString('');
    vCard.workPhone = phone_number || phone_number;
    vCard.url = `https://qrcartaoweb.com.br/user/${nickname}`;

    const file = new Blob([`${vCard.getFormattedString()}`], {
      type: 'text/vcard;charset=utf-8',
    });

    FileSaver.saveAs(file, 'temp.vcf');
  }, [userModules]);

  const closeModalAddContact = event => {
    if (event.target === event.currentTarget) {
      setActiveModalAddContact(false);
    }
  };

  function openAddContact() {
    if (!iphone()) {
      return setActiveModalAddContact(true);
    }
    saveContact();
  }

  const query = useQuery();

  function getColor(color) {
    const lightnessValue = tinycolor(color);
    if (lightnessValue.getBrightness() > 180) {
      return '#000';
    }

    return '#fff';
  }

  return (
    <>
      <Container>
        {isLoading && <Loading />}

        {!isLoading &&
          userModules &&
          !(
            userModules.exceeded_views_limit && query.get('admin') !== 'true'
          ) && (
            <>
              <Profile moduleDarkColor={userConfigs.module_dark_color}>
                {userModules.user_avatar && (
                  <div>
                    <img
                      src={userModules.user_avatar}
                      alt="avatar do usuário"
                    />
                  </div>
                )}

                <strong>{userModules.name}</strong>

                {userModules.description && <p>{userModules.description}</p>}
              </Profile>

              <MobileView>
                <ShareProfile
                  color={userConfigs.primary_color}
                  type="button"
                  onClick={() => {
                    navigator.share({
                      url: `https://qrcartaoweb.com.br/user/${nickname}`,
                    });
                  }}
                >
                  <MdShare
                    color={getColor(userConfigs.primary_color)}
                    size={22}
                  />
                </ShareProfile>
              </MobileView>

              {showAddContactButton && (
                <AddContact
                  type="button"
                  onClick={openAddContact}
                  color={userConfigs.primary_color}
                >
                  <IoPersonAddSharp
                    color={
                      !userConfigs.module_dark_color &&
                      getColor(userConfigs.primary_color)
                    }
                    size={22}
                  />
                </AddContact>
              )}

              <ListModules>
                {!!userModules.listQRCodeActive.length &&
                  userModules.listQRCodeActive.map((qrCodeItem, index) => (
                    <ButtonModule
                      title={qrCodeItem.title}
                      name={qrCodeItem.name}
                      id={qrCodeItem.id}
                      site={qrCodeItem.site}
                      icon_name={qrCodeItem.icon_name}
                      description={qrCodeItem.description}
                      index={index}
                      primaryColor={userConfigs.primary_color}
                      fontColor={userConfigs.fonto_color}
                      nickname={nickname}
                      textAlign={qrCodeItem.text_align}
                      open_default={qrCodeItem.open_default}
                    />
                  ))}
              </ListModules>

              <SocialMedia>
                {userModules.social_media.map(
                  social =>
                    social.social_link && (
                      <li>
                        <a
                          href={social.social_link}
                          alt=""
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={getSocialMediaIcon(social.social_name)}
                            alt=""
                          />
                        </a>
                      </li>
                    ),
                )}
              </SocialMedia>

              {userModules.partner && (
                <Partner href={userModules.partner.link}>
                  <span>{userModules.partner.name_profile}</span>
                  <img src={userModules.partner.logo_url} alt="" />
                </Partner>
              )}
              <AddContactAlert
                showAddContactAlert={showAddContactAlert}
                onClick={openAddContact}
              >
                <IoPersonAddSharp color="#000" size={26} />
                <p>
                  Toque aqui para adicionar
                  <strong>{` ${userModules.name} `}</strong>à sua agenda
                </p>
              </AddContactAlert>

              {activeModalAddContact && !iphone() && (
                <ModalAddContact onClick={event => closeModalAddContact(event)}>
                  <div>
                    <p>
                      Para adicionar o contato, abra o arquivo baixado e toque
                      em Importar contato
                    </p>

                    <button type="button" onClick={saveContact}>
                      Adicionar contato
                    </button>

                    <span onClick={() => setActiveModalAddContact(false)}>
                      <MdClose size={18} color="#000" />
                    </span>
                  </div>
                </ModalAddContact>
              )}
            </>
          )}

        {!userModules && <span>Usuário não encontrado</span>}

        {!isLoading &&
          userModules &&
          userModules.exceeded_views_limit &&
          query.get('admin') !== 'true' && <ExceededViews />}
      </Container>
    </>
  );
};

export default Modules;
