import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  color: #fff;
  background: #fff;
  border-radius: 25px;
  position: relative;
  top: -25px;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;

  ${({ isEmbeded }) =>
    isEmbeded &&
    css`
      margin-top: 50px !important;

      .header-module-content {
        a {
          display: none;
        }
      }
    `}
`;

export const Content = styled.div`
  padding: 0 30px 70px 30px;
  margin-top: 20px;
`;

export const Key = styled.div`
  margin-top: 18px;

  > span {
    display: block;
    color: #363636;
    margin-bottom: 5px;
    margin-left: 25px;
    text-transform: capitalize;
  }

  > p {
    width: 100%;
    height: 41px;
    background: #ececec;
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #363636;
  }

  .copy {
    width: 100%;
    height: 41px;
    background: #ff5c00;
    color: #fff;
    border-radius: 80px;
    font-size: 14px;
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 14px;

    svg {
      position: absolute;
      left: 25px;
    }
  }
`;

export const Modal = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;

  div {
    width: 86%;
    height: 130px;
    margin: 0 auto;
    background: #fff;
    color: #4f4f4f;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    top: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    span {
      display: block;

      & + span {
        margin-top: 15px;
      }
    }
  }
`;

export const HeaderModuleContent = styled.div`
  height: 56px;
  background: #f0f0f0;
  color: #363636;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  a {
    border: none;
    padding: 4px;
    position: absolute;
    left: 5px;
    background: none;
  }

  > div {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 10px;
      font-size: 16px;
    }
  }
`;
