/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
/* eslint-disable operator-linebreak */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable indent */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  MdArrowUpward,
  MdChevronLeft,
  MdClose,
  MdSearch,
  MdShare,
  MdTimer,
} from 'react-icons/md';
import { IoPersonAddSharp } from 'react-icons/io5';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import FileSaver from 'file-saver';
import vCardsJS from 'vcards-js';

import { MobileView } from 'react-device-detect';
import Countdown from 'react-countdown';
import tinycolor from 'tinycolor2';
import Header from '../../components/Header';
import {
  Container,
  Content,
  CategorySelect,
  SearchProduct,
  ProductList,
  Filters,
  ProductContainer,
  ScrollTop,
  HeaderModuleContent,
  ProductListCatalogo,
  ProductCatalogo,
  BestPrice,
  AddContact,
  ModalAddContact,
  ShareProfile,
  PromotionTime,
} from './styles';
import api from '../../services/api';
import Loading from '../../components/Loading';
import iconProducts from '../../assets/icon-products.png';
import Catalogo from './components/Catalogo';
import formatPrice from '../../helpers/formatPrice';

const QRAds = ({ moduleId }) => {
  const history = useHistory();

  const [qrAds, setQRAds] = useState(null);
  const [infosAds, setInfosAds] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id, nickname } = useParams();
  const [qrname, setQRName] = useState('');
  const [categories, setCategories] = useState([]);
  const [productsWithCategories, setProductsWithCategories] = useState(null);
  const [categorySelected, setCategorySelected] = useState('all');
  const [searchProductWord, setSearchProductWord] = useState('');
  const [productsSearched, setProductsSearched] = useState([]);
  const [isSearchProduct, setIsSeachProduct] = useState(false);
  const [primaryColor, setPrimaryColor] = useState('#FF5C00');
  const [fontColor, setFontColor] = useState('#FFF');
  const [activeModalAddContact, setActiveModalAddContact] = useState(false);
  const [backPortalLink, setBackPortalLink] = useState(null);

  const cardId = id || moduleId;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    try {
      api.get(`/products/qr_ads/${cardId}`).then(response => {
        setQRAds(response.data);
        if (response.data.user_configs) {
          if (response.data.user_configs.primary_color) {
            setPrimaryColor(response.data.user_configs.primary_color);
          }
          if (response.data.user_configs.font_color) {
            setFontColor(response.data.user_configs.font_color);
          }
        }

        const productsFormatted = response.data.products.map(product => {
          const name_formatted = product.name.substring(0, 40);
          const description_formatted = product.description
            ? product.description.substring(0, 111)
            : '';

          return {
            ...product,
            name_formatted:
              name_formatted.length >= 40
                ? `${name_formatted}...`
                : name_formatted,
            description_formatted:
              description_formatted.length >= 111
                ? `${description_formatted}...`
                : description_formatted,
          };
        });
        setProducts(productsFormatted);
        setQRName(response.data.qrname);
        setInfosAds(response.data);
        setBackPortalLink(response.data.user.back_portal_link);
      });

      api.get(`/qr-ads/p/category/${cardId}`).then(response => {
        setCategories(response.data);
      });

      const categoryParam = queryParams.get('categoria');
      if (categoryParam) {
        setCategorySelected(categoryParam);
      }
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (products.length && categories.length) {
      const categoriasDef = {};

      categoriasDef.null = {
        name: 'Sem Categoria',
        id: null,
        products: [],
      };

      categories.map(item => {
        categoriasDef[item.id] = {
          name: item.name,
          id: item.id,
          products: [],
        };
      });

      products.map(item => {
        if (categoriasDef[item.category_id]) {
          categoriasDef[item.category_id].products.push(item);
        }
      });

      setProductsWithCategories(categoriasDef);
    }
  }, [products, categories]);

  function searchProduct() {
    if (searchProductWord) {
      const findProductsSearched = products.filter(product => {
        const { name } = product;
        const nameFormatted = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const searchProductWordFormatted = searchProductWord.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        return nameFormatted.toLowerCase().includes(searchProductWordFormatted.toLowerCase());
      });

      setProductsSearched(findProductsSearched);
      setIsSeachProduct(true);

      return;
    }

    setIsSeachProduct(false);
  }

  useEffect(() => {
    if (!searchProductWord) {
      setIsSeachProduct(false);
    }
  }, [searchProductWord]);

  function scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  function searchProductKeyPress(event) {
    if (event.key === 'Enter') {
      searchProduct();
    }
  }

  const isCatalogo = useMemo(() => {
    if (qrAds) {
      return qrAds.ads_type === 'catalogo' || !qrAds.ads_type;
    }

    return false;
  }, [qrAds]);

  function getLowestPriceOfProductVariations(variations) {
    const prices = variations.map(variation => variation.price);
    return Math.min(...prices);
  }

  const saveContact = useCallback(() => {
    const { name, phone_number } = qrAds.user;
    const vCard = vCardsJS();
    vCard.firstName = name || '';
    vCard.cellPhone = phone_number || '';
    vCard.photo.embedFromString('');
    vCard.workPhone = phone_number || phone_number;
    vCard.url = `https://qrcartaoweb.com.br/user/${nickname}`;

    const file = new Blob([`${vCard.getFormattedString()}`], {
      type: 'text/vcard;charset=utf-8',
    });

    FileSaver.saveAs(file, 'temp.vcf');
  }, [qrAds]);

  const iphone = () => {
    if (typeof window === 'undefined' || typeof navigator === 'undefined') {
      return false;
    }

    return /iPhone/i.test(navigator.userAgent || navigator.vendor);
  };

  function openAddContact() {
    if (!iphone()) {
      return setActiveModalAddContact(true);
    }
    saveContact();
  }

  const closeModalAddContact = event => {
    if (event.target === event.currentTarget) {
      setActiveModalAddContact(false);
    }
  };

  const countdownRender = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Promoção finalizada</span>;
    }
    return (
      <strong>
        {`${days && `${days} dias`} ${hours}:${minutes}:${seconds}`}
      </strong>
    );
  };

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const isEmbeded = query.get('isEmbeded') === 'true';

  function getColor(color) {
    const lightnessValue = tinycolor(color);
    if (lightnessValue.getBrightness() > 180) {
      return '#000';
    }

    return '#fff';
  }

  const handleFilterCategory = (category) => {
    queryParams.set('categoria', category);
    const queryString = queryParams.toString();

    history.replace({
      pathname: location.pathname,
      search: queryString,
    });

    setCategorySelected(category);
  };

  return (
    <>
      <Header nickname={nickname} moduleId={cardId} />

      <AddContact type="button" onClick={openAddContact} color={primaryColor}>
        <IoPersonAddSharp
          color={getColor(primaryColor)}
          size={22}
        />
      </AddContact>

      <MobileView>
        <ShareProfile
          type="button"
          color={primaryColor}
          onClick={() => {
                      navigator.share({
                        url: `https://qrcartaoweb.com.br/user/${nickname}/qrads/${cardId}`,
                      });
                    }}
        >
          <MdShare color={getColor(primaryColor)} size={22} />
        </ShareProfile>
      </MobileView>

      <Container isEmbeded={isEmbeded}>
        <HeaderModuleContent className="header-module-content">
          {
            !backPortalLink && (
              <Link to={`/user/${nickname}/?open_modules=true`} type="button">
                <MdChevronLeft size={40} color="#4F4F4F" />
              </Link>
            )
          }

          {
            backPortalLink && (
              <a href={backPortalLink}>
                <MdChevronLeft size={40} color="#4F4F4F" />

              </a>
            )
          }
          <div>
            <img src={iconProducts} alt="" />
            <h3>{qrname || 'QR Produtos'}</h3>
          </div>
        </HeaderModuleContent>

        {isLoading ? (
          <Loading />
        ) : (
          <Content>
            <Filters>
              <CategorySelect>
                <span>Categorias</span>
                <div>
                  <select
                    id="categories"
                    onChange={e => handleFilterCategory(e.target.value)}
                  >
                    <option value="all">Todas Categorias</option>
                    {categories.map(category => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </CategorySelect>

              <SearchProduct>
                <button type="button" onClick={searchProduct}>
                  <MdSearch color="#C5C5C5" size={22} />
                </button>
                <input
                  value={searchProductWord}
                  onChange={event => setSearchProductWord(event.target.value)}
                  placeholder="Pesquisar produto"
                  onKeyPress={searchProductKeyPress}
                />
              </SearchProduct>
            </Filters>

            {/* Retorna todos os produtos com categoria */}
            {categorySelected === 'all' &&
              !!productsWithCategories &&
              !isSearchProduct && (
                <ProductContainer>
                  {Object.values(productsWithCategories).map(
                    categoryProducts =>
                      !!categoryProducts.products.length && (
                        <>
                          <ProductContainer.Category
                            key={categoryProducts.name}
                            style={{
                              background: primaryColor,
                              color: fontColor,
                            }}
                          >
                            {categoryProducts.name}
                          </ProductContainer.Category>

                          {/* Catalogo */}
                          {isCatalogo && (
                            <ProductList>
                              {categoryProducts.products.map(product => (
                                <Catalogo
                                  productID={product.id}
                                  productPhotoURL={product.photo_url}
                                  moduleLinkBuy={infosAds && infosAds.link_buy}
                                  productLinkBuy={product.link_buy}
                                  productDirectLink={product.direct_link}
                                  productName={product.name}
                                  productValue={product.value}
                                  productValueDiscount={product.value_discount}
                                  productVariations={product.product_variations}
                                  allowPromotionTime={product.allow_promotion_time && product.show_promotion_time}
                                  promotionTime={product.promotion_time}
                                  showBenefitClub={product.show_benefit_club}
                                  nickname={nickname}
                                />
                              ))}
                            </ProductList>
                          )}

                          {/* Cardapio */}
                          {!isCatalogo && (
                            <ProductListCatalogo>
                              {categoryProducts.products.map(product => (
                                <ProductCatalogo
                                  key={product.id}
                                  onClick={() => {
                                    if (
                                      (!!infosAds.link_buy ||
                                        !!product.link_buy) &&
                                      product.direct_link
                                    ) {
                                      window.open(
                                        product.link_buy
                                          ? product.link_buy
                                          : infosAds.link_buy,
                                      );
                                    } else {
                                      history.push(`/user/${nickname}/qrads/produto/${product.id}`);
                                    }
                                  }}
                                >
                                  <LazyLoad height={150} offset={20}>
                                    <div className="content">
                                      <strong>{product.name_formatted}</strong>
                                      <p>{product.description_formatted}</p>

                                      {
                                        !product.product_variations.length && (
                                          <>
                                            <span>
                                              {!!product.value &&
                                          !product.value_discount &&
                                          formatPrice(product.value)}
                                            </span>

                                            {!!product.value_discount && (
                                            <div className="discount">
                                              <strong>
                                                {formatPrice(
                                              product.value_discount,
                                            )}
                                              </strong>

                                              <span>
                                                {formatPrice(product.value)}
                                              </span>
                                            </div>
                                      )}
                                          </>
                                        )
                                      }

                                      {
                                        product.product_variations.length && (
                                          <BestPrice>
                                            <span>a partir de </span>
                                            <strong>
                                              {formatPrice(
                                              getLowestPriceOfProductVariations(product.product_variations),
                                            )}
                                            </strong>
                                          </BestPrice>
                                        )
                                      }

                                      {product.allow_promotion_time && product.show_promotion_time && (
                                      <PromotionTime>
                                        <span>Termina em:</span>
                                        <div>
                                          <MdTimer size={18} color="#ff0000" />
                                          <Countdown
                                            date={new Date(product.promotion_time)}
                                            renderer={countdownRender}
                                          />
                                        </div>
                                      </PromotionTime>
                                      )}
                                    </div>

                                    <img
                                      src={product.photo_url}
                                      alt="produto"
                                    />
                                  </LazyLoad>
                                </ProductCatalogo>
                              ))}
                            </ProductListCatalogo>
                          )}
                        </>
                      ),
                  )}
                </ProductContainer>
              )}

            {/* Retorna produtos de categoria selecionada */}
            {categorySelected !== 'all' && !isSearchProduct && productsWithCategories && (
              <ProductContainer>
                <>
                  <ProductContainer.Category
                    style={{
                      background: primaryColor,
                      color: fontColor,
                    }}
                  >
                    {productsWithCategories[categorySelected].name}
                  </ProductContainer.Category>

                  {/* Catalogo */}
                  {isCatalogo && (
                    <ProductList>
                      {productsWithCategories[categorySelected].products.map(
                        product => (
                          <Catalogo
                            productID={product.id}
                            productPhotoURL={product.photo_url}
                            moduleLinkBuy={infosAds && infosAds.link_buy}
                            productLinkBuy={product.link_buy}
                            productDirectLink={product.direct_link}
                            productName={product.name}
                            productValue={product.value}
                            productValueDiscount={product.value_discount}
                            productVariations={product.product_variations}
                            allowPromotionTime={product.allow_promotion_time && product.show_promotion_time}
                            promotionTime={product.promotion_time}
                            showBenefitClub={product.show_benefit_club}
                            nickname={nickname}
                          />
                        ),
                      )}
                    </ProductList>
                  )}

                  {/* Cardapio */}
                  {!isCatalogo && (
                    <ProductListCatalogo>
                      {productsWithCategories[categorySelected].products.map(
                        product => (
                          <ProductCatalogo
                            key={product.id}
                            onClick={() => {
                              if (
                                (!!infosAds.link_buy || !!product.link_buy) &&
                                product.direct_link
                              ) {
                                window.open(
                                  product.link_buy
                                    ? product.link_buy
                                    : infosAds.link_buy,
                                );
                              } else {
                                history.push(`/user/${nickname}/qrads/produto/${product.id}`);
                              }
                            }}
                          >
                            <LazyLoad height={150} offset={20}>
                              <div className="content">
                                <strong>{product.name_formatted}</strong>
                                <p>{product.description_formatted}</p>

                                {
                                        !product.product_variations.length && (
                                          <>
                                            <span>
                                              {!!product.value &&
                                          !product.value_discount &&
                                          formatPrice(product.value)}
                                            </span>

                                            {!!product.value_discount && (
                                            <div className="discount">
                                              <strong>
                                                {formatPrice(
                                              product.value_discount,
                                            )}
                                              </strong>

                                              <span>
                                                {formatPrice(product.value)}
                                              </span>
                                            </div>
                                      )}
                                          </>
                                        )
                                      }

                                {
                                        product.product_variations.length && (
                                          <BestPrice>
                                            <span>a partir de </span>
                                            <strong>
                                              {formatPrice(
                                              getLowestPriceOfProductVariations(product.product_variations),
                                            )}
                                            </strong>
                                          </BestPrice>
                                        )
                                      }
                              </div>

                              <img src={product.photo_url} alt="produto" />
                            </LazyLoad>
                          </ProductCatalogo>
                        ),
                      )}
                    </ProductListCatalogo>
                  )}
                </>
              </ProductContainer>
            )}

            {/* Retorna busca de produtos */}
            {isSearchProduct && (
              <ProductContainer>
                <>
                  <ProductContainer.Category
                    style={{
                      background: primaryColor,
                      color: fontColor,
                    }}
                  >
                    Busca
                  </ProductContainer.Category>

                  {/* Catalogo */}
                  {isCatalogo && (
                    <ProductList>
                      {productsSearched.map(product => (
                        <Catalogo
                          productID={product.id}
                          productPhotoURL={product.photo_url}
                          moduleLinkBuy={infosAds && infosAds.link_buy}
                          productLinkBuy={product.link_buy}
                          productDirectLink={product.direct_link}
                          productName={product.name}
                          productValue={product.value}
                          productValueDiscount={product.value_discount}
                          productVariations={product.product_variations}
                          allowPromotionTime={product.allow_promotion_time && product.show_promotion_time}
                          promotionTime={product.promotion_time}
                          showBenefitClub={product.show_benefit_club}
                          nickname={nickname}
                        />
                      ))}
                    </ProductList>
                  )}

                  {/* Cardapio */}
                  {!isCatalogo && (
                    <ProductListCatalogo>
                      {productsSearched.map(product => (
                        <ProductCatalogo
                          key={product.id}
                          onClick={() => {
                            if (
                              (!!infosAds.link_buy || !!product.link_buy) &&
                              product.direct_link
                            ) {
                              window.open(
                                product.link_buy
                                  ? product.link_buy
                                  : infosAds.link_buy,
                              );
                            } else {
                              history.push(`/user/${nickname}/qrads/produto/${product.id}`);
                            }
                          }}
                        >
                          <LazyLoad height={150} offset={20}>
                            <div className="content">
                              <strong>{product.name_formatted}</strong>
                              <p>{product.description_formatted}</p>

                              {
                                        !product.product_variations.length && (
                                          <>
                                            <span>
                                              {!!product.value &&
                                          !product.value_discount &&
                                          formatPrice(product.value)}
                                            </span>

                                            {!!product.value_discount && (
                                            <div className="discount">
                                              <strong>
                                                {formatPrice(
                                              product.value_discount,
                                            )}
                                              </strong>

                                              <span>
                                                {formatPrice(product.value)}
                                              </span>
                                            </div>
                                      )}
                                          </>
                                        )
                                      }

                              {
                                        product.product_variations.length && (
                                          <BestPrice>
                                            <span>a partir de </span>
                                            <strong>
                                              {formatPrice(
                                              getLowestPriceOfProductVariations(product.product_variations),
                                            )}
                                            </strong>
                                          </BestPrice>
                                        )
                                      }
                            </div>

                            <img src={product.photo_url} alt="produto" />
                          </LazyLoad>
                        </ProductCatalogo>
                      ))}
                    </ProductListCatalogo>
                  )}
                </>
              </ProductContainer>
            )}
          </Content>
        )}

        <ScrollTop onClick={scrollTop} style={{ background: primaryColor }}>
          <div style={{ background: fontColor }}>
            <MdArrowUpward size={30} color={primaryColor} />
          </div>
        </ScrollTop>
      </Container>

      {activeModalAddContact && !iphone() && (
        <ModalAddContact onClick={event => closeModalAddContact(event)}>
          <div>
            <p>
              Para adicionar o contato, abra o arquivo baixado e toque
              em Importar contato
            </p>

            <button type="button" onClick={saveContact}>
              Adicionar contato
            </button>

            <span onClick={() => setActiveModalAddContact(false)}>
              <MdClose size={18} color="#000" />
            </span>
          </div>
        </ModalAddContact>
      )}
    </>
  );
};

export default QRAds;
