import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 500px;

  > div {
    border-radius: 30px;
    background: #262626;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 372px;
    width: 335px;
    margin: 130px auto 0 auto;
    height: 200px;
    height: 220px;

    strong {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    p {
      font-size: 13px;
    }
  }
`;
