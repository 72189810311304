import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.li`
  border-radius: 15px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 85%;
`;

export const ButtonModuleLink = styled(Link)`
  background: ${props => props.primaryColor};
  width: 100%;
  min-height: 41px;
  color: ${props => props.fontColor};
  display: flex;
  align-items: center;
  border-radius: 15px;

  span {
    text-align: center;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    display: block;
    max-width: 73%;
    margin: 0 auto;
  }

  svg {
    position: absolute;
    left: 16px;
  }
`;

export const ButtonDirectLink = styled.a`
  background: ${props => props.primaryColor};
  width: 100%;
  min-height: 41px;
  color: ${props => props.fontColor};
  display: flex;
  align-items: center;
  border-radius: 15px;

  span {
    text-align: center;
    font-weight: 500;
    position: relative;
    font-size: 16px;
    display: block;
    max-width: 73%;
    margin: 0 auto;
  }

  svg {
    position: absolute;
    left: 16px;
  }
`;

export const ButtonAlert = styled.div`
  position: relative;
  width: 100%;

  button {
    padding: 5px 0;
    display: flex;
    align-items: center;
    position: relative;
    background: ${props => props.primaryColor};
    border-radius: 15px;
    width: 100%;
    min-height: 41px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    z-index: 2;

    svg {
      position: absolute;
      left: 16px;
    }

    span {
      display: block;
      max-width: 73%;
      margin: 0 auto;
      color: ${props => props.fontColor};
    }
  }

  .description {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    position: relative;
    background: #fff;
    color: #000;
    padding: 13px 15px;
    font-size: 13px;
    display: none;
    text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
    white-space: pre-line;
    word-break: break-word;

    &.is_open {
      display: block;
    }

    &::before {
      content: '';
      position: absolute;
      top: -25px;
      left: 0;
      width: 100%;
      height: 30px;
      background: #fff;
      z-index: 1;
    }
  }
`;
