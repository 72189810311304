import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => (color ? `${color}` : '#4f4f4f')};
`;
