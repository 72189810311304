import React from 'react';

import { Container } from './styles';

const NotFound = ({ message, color }) => (
  <Container color={color}>
    <p>{message || 'não encontrado'}</p>
  </Container>
);

export default NotFound;
