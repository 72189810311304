import React, { useState, useEffect } from 'react';
import { MdChevronLeft, MdContentCopy } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import Header from '../../components/Header';
import { Container, Content, HeaderModuleContent, Key, Modal } from './styles';
import api from '../../services/api';
import Loading from '../../components/Loading';
import iconPixSmall from '../../assets/icon-discount-small.png';

const QRPix = ({ moduleId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pixList, setPixList] = useState([]);
  const { id, nickname } = useParams();
  const [activeModal, setActiveModal] = useState(false);
  const [modalPix, setModalPix] = useState({});

  const cardId = id || moduleId;

  useEffect(() => {
    try {
      api.get(`/qr-pix/p/${cardId}`).then(response => {
        setPixList(response.data);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  function openModal(pix) {
    copy(pix.key_value);
    setActiveModal(true);
    setModalPix(pix);
  }

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const isEmbeded = query.get('isEmbeded') === 'true';

  return (
    <>
      <Header nickname={nickname} moduleId={cardId} />

      <Container isEmbeded={isEmbeded}>
        <HeaderModuleContent className="header-module-content">
          <Link to={`/user/${nickname}/?open_modules=true`} type="button">
            <MdChevronLeft size={40} color="#4F4F4F" />
          </Link>
          <div>
            <img src={iconPixSmall} alt="" />
            <h3>Pix</h3>
          </div>
        </HeaderModuleContent>

        {isLoading ? (
          <Loading />
        ) : (
          <Content>
            {pixList.map(
              pix =>
                pix.bank_name && (
                  <Key key={pix.key_value}>
                    <span>
                      {pix.identifier ? `${pix.identifier}:` : 'Chave Pix:'}
                    </span>

                    <p>{pix.key_value}</p>

                    <div className="copy" onClick={() => openModal(pix)}>
                      <MdContentCopy size={18} color="#fff" />

                      <span>Copiar Chave</span>
                    </div>
                  </Key>
                ),
            )}
          </Content>
        )}

        {activeModal && (
          <Modal onClick={() => setActiveModal(false)}>
            <div>
              <span>{`A chave ${modalPix.bank_name} foi copiada com sucesso`}</span>
              <span>{` Chave: ${modalPix.key_value}`}</span>
            </div>
          </Modal>
        )}
      </Container>
    </>
  );
};

export default QRPix;
