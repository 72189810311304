import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import assinatura from '../../assets/assinatura.png';
import { Banner, Container } from './styles';
import bannerLogo from '../../assets/banner-logo.png';
import api from '../../services/api';

const Footer = () => {
  const [user, setUser] = useState('');

  useEffect(() => {
    const nickname = window.location.pathname.split('/')[2];
    api.get(`/qr-codes/${nickname}`).then(response => {
      setUser(response.data);
    });
  }, []);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const isEmbeded = query.get('isEmbeded') === 'true';

  return (
    <>
      {!isEmbeded && (
        <Container
          onClick={() => {
            window.location.assign('https://qrcartaoweb.com.br/user/qrunico');
          }}
        >
          {user && user.plan_name !== 'qr-free' && (
            <img src={assinatura} alt="assinatura" style={{ height: 28 }} />
          )}

          {user && user.plan_name === 'qr-free' && (
            <Banner
              href="https://qrcartaoweb.com.br/user/qrunico"
              target="_blank"
            >
              <p>Crie um site como esse gratuitamente em </p>
              <img src={bannerLogo} alt="QR Único" />
            </Banner>
          )}
        </Container>
      )}
    </>
  );
};
export default Footer;
