import React, { useState, useEffect } from 'react';
import { MdChevronLeft, MdCloudDownload } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';

import Header from '../../components/Header';
import { Container, Content, HeaderModuleContent } from './styles';
import api from '../../services/api';
import Loading from '../../components/Loading';
import NotFound from '../../components/NotFound';

const QRGeneric = ({ moduleId }) => {
  const [generic, setGeneric] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPDF, setIsPDF] = useState(false);

  const { id, nickname } = useParams();

  const cardId = id || moduleId;

  useEffect(() => {
    try {
      api.get(`/qr-generics/p/${cardId}`).then(response => {
        setGeneric(response.data);
        if (
          response.data.filename_url &&
          response.data.filename_url.split('.').pop() === 'pdf'
        ) {
          setIsPDF(true);
        }

        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const isEmbeded = query.get('isEmbeded') === 'true';

  return (
    <>
      <Header nickname={nickname} moduleId={cardId} />

      <Container isEmbeded={isEmbeded}>
        <HeaderModuleContent className="header-module-content">
          <Link to={`/user/${nickname}/?open_modules=true`} type="button">
            <MdChevronLeft size={40} color="#4F4F4F" />
          </Link>
          <div>
            <h3>{generic ? generic.qrname : 'QR Genérico'}</h3>
          </div>
        </HeaderModuleContent>

        {isLoading && <Loading />}

        {!isLoading && generic && (
          <Content>
            <a
              href={generic.filename_url}
              download
              target="_blank"
              rel="noreferrer"
            >
              <MdCloudDownload size={22} color="#363636" />
            </a>
            {isPDF ? (
              <iframe
                title="titulo"
                src={`${generic.filename_url}#toolbar=0`}
                width="320"
                height="500"
              />
            ) : (
              <PinchZoomPan
                maxScale={5}
                zoomButtons={false}
                doubleTapBehavior="zoom"
              >
                <img src={generic.filename_url} alt="generic" />
              </PinchZoomPan>
            )}
          </Content>
        )}

        {!isLoading && !generic && (
          <NotFound message="QR Genérico não encontrado" />
        )}
      </Container>
    </>
  );
};

export default QRGeneric;
