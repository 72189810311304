import React from 'react';
import {
  MdInsertComment,
  MdCreditCard,
  MdWifi,
  MdMonetizationOn,
  MdReceipt,
  MdLibraryBooks,
  MdStyle,
  MdShoppingCart,
  MdLink,
  MdPlace,
  MdPhone,
  MdMessage,
  MdHelp,
  MdInfo,
  MdLanguage,
  MdSmartphone,
  MdAccountBalance,
  MdBookmark,
  MdDateRange,
  MdCameraAlt,
  MdCheckCircle,
  MdFileDownload,
  MdQuestionAnswer,
  MdLightbulbOutline,
  MdLock,
  MdSecurity,
  MdShare,
  MdStar,
  MdStore,
  MdTimer,
  MdWidgets,
  MdHome,
  MdArrowBack,
  MdArrowDownward,
  MdArrowForward,
  MdArrowUpward,
  MdLocalDining,
  MdDirectionsBus,
  MdDirectionsCar,
  MdDirectionsBike,
  MdDirectionsRun,
  MdMotorcycle,
  MdLocalBar,
  MdLocalDrink,
  MdCake,
} from 'react-icons/md';
import {
  FaAppleAlt,
  FaBriefcaseMedical,
  FaBuffer,
  FaBuilding,
  FaChartBar,
  FaFacebook,
  FaGift,
  FaHeart,
  FaInstagram,
  FaLinkedin,
  FaPercentage,
  FaQrcode,
  FaTag,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import {
  Container,
  ButtonAlert,
  ButtonDirectLink,
  ButtonModuleLink,
} from './styles';

const ButtonModule = ({
  title,
  name,
  id,
  site,
  icon_name,
  description,
  index,
  primaryColor,
  fontColor,
  nickname,
  textAlign,
  open_default,
}) => {
  function getIcon(iconName) {
    switch (iconName) {
      case 'whatsapp':
        return <FaWhatsapp size={22} color={fontColor} />;
      case 'instagram':
        return <FaInstagram size={22} color={fontColor} />;
      case 'linkedin':
        return <FaLinkedin size={22} color={fontColor} />;
      case 'facebook':
        return <FaFacebook size={22} color={fontColor} />;
      case 'twitter':
        return <FaTwitter size={22} color={fontColor} />;
      case 'youtube':
        return <FaYoutube size={22} color={fontColor} />;
      case 'map-marker':
        return <MdPlace size={22} color={fontColor} />;
      case 'phone':
        return <MdPhone size={22} color={fontColor} />;
      case 'message-bulleted':
        return <MdMessage size={22} color={fontColor} />;
      case 'help-circle':
        return <MdHelp size={22} color={fontColor} />;
      case 'information':
        return <MdInfo size={22} color={fontColor} />;
      case 'web':
        return <MdLanguage size={22} color={fontColor} />;
      case 'cellphone':
        return <MdSmartphone size={22} color={fontColor} />;
      case 'bank':
        return <MdAccountBalance size={22} color={fontColor} />;
      case 'bookmark':
        return <MdBookmark size={22} color={fontColor} />;
      case 'buffer':
        return <FaBuffer size={22} color={fontColor} />;
      case 'calendar-month':
        return <MdDateRange size={22} color={fontColor} />;
      case 'camera':
        return <MdCameraAlt size={22} color={fontColor} />;
      case 'chart-bar':
        return <FaChartBar size={22} color={fontColor} />;
      case 'check-circle':
        return <MdCheckCircle size={22} color={fontColor} />;
      case 'download':
        return <MdFileDownload size={22} color={fontColor} />;
      case 'gift':
        return <FaGift size={22} color={fontColor} />;
      case 'forum':
        return <MdQuestionAnswer size={22} color={fontColor} />;
      case 'heart':
        return <FaHeart size={22} color={fontColor} />;
      case 'lightbulb-on':
        return <MdLightbulbOutline size={22} color={fontColor} />;
      case 'lock':
        return <MdLock size={22} color={fontColor} />;
      case 'qrcode':
        return <FaQrcode size={22} color={fontColor} />;
      case 'sale':
        return <FaPercentage size={22} color={fontColor} />;
      case 'security':
        return <MdSecurity size={22} color={fontColor} />;
      case 'share-variant':
        return <MdShare size={22} color={fontColor} />;
      case 'star':
        return <MdStar size={22} color={fontColor} />;
      case 'store':
        return <MdStore size={22} color={fontColor} />;
      case 'tag':
        return <FaTag size={22} color={fontColor} />;
      case 'timer':
        return <MdTimer size={22} color={fontColor} />;
      case 'widgets':
        return <MdWidgets size={22} color={fontColor} />;
      case 'home':
        return <MdHome size={22} color={fontColor} />;
      case 'arrow-left':
        return <MdArrowBack size={22} color={fontColor} />;
      case 'arrow-down':
        return <MdArrowDownward size={22} color={fontColor} />;
      case 'arrow-right':
        return <MdArrowForward size={22} color={fontColor} />;
      case 'arrow-up':
        return <MdArrowUpward size={22} color={fontColor} />;
      case 'food':
        return <MdLocalDining size={22} color={fontColor} />;
      case 'food-fork-drink':
        return <MdLocalDining size={22} color={fontColor} />;
      case 'bus':
        return <MdDirectionsBus size={22} color={fontColor} />;
      case 'car':
        return <MdDirectionsCar size={22} color={fontColor} />;
      case 'bike':
        return <MdDirectionsBike size={22} color={fontColor} />;
      case 'run':
        return <MdDirectionsRun size={22} color={fontColor} />;
      case 'motorbike':
        return <MdMotorcycle size={22} color={fontColor} />;
      case 'beer':
        return <MdLocalBar size={22} color={fontColor} />;
      case 'cupcake':
        return <MdCake size={22} color={fontColor} />;
      case 'cup':
        return <MdLocalDrink size={22} color={fontColor} />;
      case 'medical-bag':
        return <FaBriefcaseMedical size={22} color={fontColor} />;
      case 'office-building':
        return <FaBuilding size={22} color={fontColor} />;
      case 'food-apple':
        return <FaAppleAlt size={22} color={fontColor} />;
      case 'no-icon':
        return '';
      default:
        return <MdLink size={22} color={fontColor} />;
    }
  }

  function getModuleIcon(moduleTitle, moduleName) {
    switch (moduleTitle) {
      case 'QR Code Wifi':
        return <MdWifi size={22} color={fontColor} />;
      case 'QR Code Message':
        return <MdInsertComment size={22} color={fontColor} />;
      case 'QR Code Desconto':
        return <MdMonetizationOn size={22} color={fontColor} />;
      case 'QR Code Panfleto':
        return <MdReceipt size={22} color={fontColor} />;
      case 'QR Code Generic':
        return <MdLibraryBooks size={22} color={fontColor} />;
      case 'QR Code Pix':
        return <MdStyle size={22} color={fontColor} />;
      case 'QR Code Cartão':
        return <MdCreditCard size={22} color={fontColor} />;
      case 'QR Code Ads':
        return <MdShoppingCart size={22} color={fontColor} />;
      case 'QR Code Site':
        return getIcon(moduleName);
      case 'QR Alerts':
        return getIcon(moduleName);
      default:
        return '';
    }
  }

  function getModuleUrl(moduleTitle, moduleId) {
    switch (moduleTitle) {
      case 'QR Code Wifi':
        return `/user/${nickname}/qrwifi/${moduleId}`;
      case 'QR Code Message':
        return `/user/${nickname}/qrmessage/${moduleId}`;
      case 'QR Code Desconto':
        return `/user/${nickname}/qrdiscount/${moduleId}`;
      case 'QR Code Panfleto':
        return `/user/${nickname}/qrflyer/${moduleId}`;
      case 'QR Code Generic':
        return `/user/${nickname}/qrgeneric/${moduleId}`;
      case 'QR Code Pix':
        return `/user/${nickname}/qrpix/${moduleId}`;
      case 'QR Code Cartão':
        return `/user/${nickname}/qrcard/${moduleId}`;
      case 'QR Code Ads':
        return `/user/${nickname}/qrads/${moduleId}`;
      default:
        return '/';
    }
  }

  function getNameModule(moduleTitle, moduleName) {
    switch (moduleTitle) {
      case 'QR Code Cartão':
        return 'Cartão de Visita Digital';
      case 'QR Code Generic':
        return moduleName;
      case 'QR Code Message':
        return moduleName;
      default:
        return moduleName;
    }
  }

  function openAlert(elementId) {
    const findAlert = document.querySelector(elementId);
    findAlert.classList.toggle('is_open');
  }

  return (
    <Container key={id}>
      {/* Outros modulos */}
      {title !== 'QR Code Site' && title !== 'QR Alerts' && (
        <ButtonModuleLink
          to={getModuleUrl(title, id)}
          primaryColor={primaryColor}
          fontColor={fontColor}
        >
          {getModuleIcon(title)}
          <span style={{ color: fontColor }}>{getNameModule(title, name)}</span>
        </ButtonModuleLink>
      )}

      {/* Sites */}
      {title === 'QR Code Site' && (
        <ButtonDirectLink
          primaryColor={primaryColor}
          fontColor={fontColor}
          href={site}
          title="link"
          target="_blank"
          rel="noreferrer"
        >
          {icon_name && getModuleIcon(title, icon_name)}
          <span style={{ color: fontColor }}>{getNameModule(title, name)}</span>
        </ButtonDirectLink>
      )}

      {/* Alerta */}
      {title === 'QR Alerts' && (
        <ButtonAlert
          primaryColor={primaryColor}
          fontColor={fontColor}
          textAlign={textAlign}
        >
          <button type="button" onClick={() => openAlert(`#alert_${index}`)}>
            {icon_name && getModuleIcon(title, icon_name)}
            <span>{name}</span>
          </button>

          <div
            className={`description ${open_default ? 'is_open' : ''}`}
            id={`alert_${index}`}
          >
            <p>{description && description}</p>
          </div>
        </ButtonAlert>
      )}
    </Container>
  );
};

export default ButtonModule;
