import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  color: #fff;
  background: #fff;
  border-radius: 25px;
  position: relative;
  top: -25px;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;

  ${({ isEmbeded }) =>
    isEmbeded &&
    css`
      margin-top: 50px !important;

      .header-module-content {
        a {
          display: none;
        }
      }
    `}
`;

export const HeaderModuleContent = styled.div`
  height: 56px;
  background: #f0f0f0;
  color: #363636;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  a {
    border: none;
    padding: 4px;
    position: absolute;
    left: 5px;
    background: none;
  }

  > div {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 10px;
      font-size: 16px;
    }
  }
`;

export const Content = styled.div`
  padding: 0 20px;
  max-width: 420px;
  margin: 40px auto 0 auto;

  img {
    width: 100%;
  }

  > a {
    border: none;
  }
`;
