/* eslint-disable operator-linebreak */
import React, { useState, useEffect } from 'react';
import {
  IoLogoWhatsapp,
  IoCall,
  IoLogoInstagram,
  IoGlobeOutline,
  IoLogoYoutube,
  IoMail,
  IoLocation,
  IoLogoLinkedin,
} from 'react-icons/io5';
import { IoLogoFacebook } from 'react-icons/io';
import { useParams, Link } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import NotFound from '../../components/NotFound';

import GlobalStyles, { Container, Card, Header } from './styles';
import api from '../../services/api';
import Loading from '../../components/Loading';
import logoCompany from '../../assets/logo-mini.png';

const QRCard = ({ moduleId }) => {
  const [card, setCard] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fullAndres, setFullAndres] = useState('');

  const { id, nickname } = useParams();

  const cardId = id || moduleId;

  useEffect(() => {
    try {
      api.get(`/qr-cards/p/${cardId}`).then(response => {
        setCard({
          ...response.data,
          backgroundURL: `https://qrcode-api.s3.amazonaws.com/fundos/fundo_${String(
            response.data.theme,
          ).padStart(2, '0')}.png`,
          whatsapp:
            response.data.whatsapp.substr(0, 2) === '55'
              ? response.data.whatsapp
              : `55${response.data.whatsapp}`,
        });
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let message = '';

    if (card) {
      if (card.andress) {
        message = `${message}${card.andress}. `;
      }

      if (card.andress_number) {
        message = `${message}${card.andress_number}. `;
      }

      if (card.complement) {
        message = `${message}${card.complement}. `;
      }

      if (card.neighborhood) {
        message = `${message}${card.neighborhood}. `;
      }

      if (card.city) {
        message = `${message}${card.city}. `;
      }

      if (card.uf) {
        message = `${message}${card.uf}. `;
      }

      if (card.cep) {
        message = `${message}${card.cep}. `;
      }
    }

    setFullAndres(message);
  }, [card]);

  return (
    <>
      <Container>
        {isLoading && <Loading />}

        {!isLoading && card && (
          <>
            <Header>
              <Link to={`/user/${nickname}/?open_modules=true`} type="button">
                <MdChevronLeft size={40} color="#fff" />
              </Link>
            </Header>

            <Card
              backgroundColor={card.background}
              background={
                card.show_my_background && !!card.my_background
                  ? card.my_background_url
                  : card.backgroundURL
              }
              fontColor={card.font}
            >
              <Card.Logo logo={card.logo_url} showLogo={card.show_logo} />

              <Card.Content showBackgroundInfo={card.show_background_info}>
                <div className="line">
                  <h3>{card.line_one}</h3>
                  <span>{card.line_two}</span>
                </div>

                <div className="line">
                  <h3>{card.line_three}</h3>
                  <span>{card.line_four}</span>
                </div>

                <p className="andress">{fullAndres}</p>

                {card.phone_number_2 && <span>{card.phone_number_2}</span>}
                {card.info && <span>{card.info}</span>}
              </Card.Content>

              <Card.Links>
                {card.show_whatsapp && card.whatsapp && (
                  <a
                    href={`https://wa.me/${card.whatsapp}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IoLogoWhatsapp
                      color={card.icons_color || card.font}
                      size={36}
                    />
                  </a>
                )}

                <a href={`tel:${card.phone_number_1}`}>
                  <IoCall color={card.icons_color || card.font} size={36} />
                </a>

                {card.show_instagram && card.instagram && (
                  <a
                    href={`https://www.instagram.com/${card.instagram}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IoLogoInstagram
                      color={card.icons_color || card.font}
                      size={36}
                    />
                  </a>
                )}

                {card.show_facebook && card.facebook && (
                  <a href={card.facebook} target="_blank" rel="noreferrer">
                    <IoLogoFacebook
                      color={card.icons_color || card.font}
                      size={36}
                    />
                  </a>
                )}

                {card.show_site && card.site && (
                  <a href={card.site} target="_blank" rel="noreferrer">
                    <IoGlobeOutline
                      color={card.icons_color || card.font}
                      size={36}
                    />
                  </a>
                )}

                {card.show_youtube && card.youtube && (
                  <a href={card.youtube} target="_blank" rel="noreferrer">
                    <IoLogoYoutube
                      color={card.icons_color || card.font}
                      size={36}
                    />
                  </a>
                )}

                {card.show_email && card.email && (
                  <a href={`mailto:${card.email}`}>
                    <IoMail color={card.icons_color || card.font} size={36} />
                  </a>
                )}

                {card.show_google_maps && card.google_maps && (
                  <a href={card.google_maps} target="_blank" rel="noreferrer">
                    <IoLocation
                      color={card.icons_color || card.font}
                      size={36}
                    />
                  </a>
                )}

                {card.show_linkedin && card.linkedin && (
                  <a
                    href={`https://br.linkedin.com/in/${card.linkedin}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IoLogoLinkedin
                      color={card.icons_color || card.font}
                      size={36}
                    />
                  </a>
                )}
              </Card.Links>

              <Card.LogoCompany>
                {card.show_signature && (
                  <a
                    href="https://qrcartao.com.br"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img src={logoCompany} alt="logo" />
                  </a>
                )}
              </Card.LogoCompany>
            </Card>
          </>
        )}

        {!isLoading && !card && (
          <NotFound message="Cartão não encontrado" color="#fff" />
        )}
      </Container>

      <GlobalStyles />
    </>
  );
};

export default QRCard;
