import styled from 'styled-components/macro';

const productWith =
  window.innerWidth > 420 ? 420 / 2 - 10 : window.innerWidth / 2 - 10;

export const Product = styled.div`
  border-radius: 5px;
  width: calc(50% - 10px);
  margin: 12px 5px 0px 5px;
  color: #4f4f4f;
  border: 1px solid #dcdcdc;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;

  .lazyload-wrapper {
    height: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  img {
    width: ${productWith}px;
    height: ${productWith}px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    object-fit: cover;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 5px 11px 12px 11px;
    position: relative;

    .discount-flag {
      padding: 0 5px;
      height: 18.7px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #63a66e;
      border-radius: 3px;
      position: absolute;
      top: -23px;
      left: 7px;

      span {
        font-size: 10px;
        font-weight: 600;
        color: #fff;
      }
    }

    > span {
      text-align: left;
      font-size: 15px;
      margin-top: 6px;
      display: block;
      font-weight: 500;
    }

    .price {
      display: flex;
      align-items: center;

      &-value {
        font-size: 20px;
        display: block;
        margin-top: 10px;
        font-weight: 600;
        font-size: 13px;
        color: #363636;

        &.discount {
          font-size: 14px;
          text-decoration: line-through;
          font-weight: 500;
          font-size: 10px;
          color: #767676;
        }
      }

      &-discount {
        font-size: 13px;
        display: block;
        margin-top: 10px;
        font-weight: 600;
        color: #63a66e;
        margin-right: 15px;
      }
    }
  }

  button {
    background: #3cd378;
    color: #fff;
    border: none;
    position: relative;
    bottom: 1px;
    width: 100%;
    border-radius: 15px;
    font-size: 14px;
    font-weight: 600;
    height: 33px;
  }
`;

export const BestPrice = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  span {
    margin-bottom: 6px;
    font-size: 8px;
    color: #646464;
  }

  strong {
    font-size: 13px;
    color: #363636;
  }
`;

export const PromotionTime = styled.div`
  text-align: left;

  span {
    color: #ff0000;
    font-size: 7px;
    font-weight: 500;
  }

  > div {
    margin-top: 5px;
    display: flex;
    align-items: center;

    strong {
      color: #ff0000;
      margin-left: 5px;
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

export const PromotionTimeContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
`;

export const BenefitClub = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 6px;
    color: #363636;
    font-weight: 600;
  }

  div {
    margin-top: 3px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff0000;
    font-size: 8px;
    color: #fff;
  }
`;
