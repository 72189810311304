/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable operator-linebreak */
import React, { useMemo } from 'react';
import LazyLoad from 'react-lazyload';
import { MdTimer } from 'react-icons/md';
import Countdown from 'react-countdown';
import { useHistory } from 'react-router-dom';

import formatPrice from '../../../../helpers/formatPrice';
import {
  BestPrice,
  Product,
  PromotionTime,
  PromotionTimeContainer,
  BenefitClub,
} from './styles';

const Catalogo = ({
  productID,
  productPhotoURL,
  moduleLinkBuy,
  productLinkBuy,
  productDirectLink,
  productName,
  productValue,
  productValueDiscount,
  productVariations,
  allowPromotionTime,
  promotionTime,
  showBenefitClub,
  nickname,
}) => {
  const history = useHistory();

  function formatProductName(product_name) {
    const name_formatted = product_name.substring(0, 50);

    return name_formatted.length >= 50
      ? `${name_formatted}...`
      : name_formatted;
  }

  function getLowestPriceOfProductVariations(variations) {
    const prices = variations.map(variation => variation.price);
    return Math.min(...prices);
  }

  const getDiscountPercent = useMemo(() => {
    const result = (1 - productValueDiscount / productValue) * 100;
    return result.toFixed(0);
  }, [productValueDiscount, productValue]);

  const countdownRender = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Promoção finalizada</span>;
    }
    return (
      <strong>
        {days && `${days} dias`} {hours}:{minutes}:{seconds}
      </strong>
    );
  };

  return (
    <Product key={productID}>
      <LazyLoad height={150} offset={20}>
        <div className="container">
          <img
            src={productPhotoURL}
            alt="produto"
            onClick={() => {
              if ((!!moduleLinkBuy || !!productLinkBuy) && productDirectLink) {
                window.open(productLinkBuy || moduleLinkBuy);
              } else {
                history.push(`/user/${nickname}/qrads/produto/${productID}`);
              }
            }}
          />

          <div className="content">
            {!!productValueDiscount && (
              <div className="discount-flag">
                <span>{`${getDiscountPercent}%`} OFF</span>
              </div>
            )}

            <span>{formatProductName(productName)}</span>

            {!!productValue && !productVariations.length && (
              <div className="price">
                {!!productValueDiscount && (
                  <p className="price-discount">
                    {formatPrice(productValueDiscount)}
                  </p>
                )}

                <strong
                  className={`price-value ${
                    productValueDiscount ? 'discount' : ''
                  }`}
                >
                  {formatPrice(productValue)}
                </strong>
              </div>
            )}

            {!!productVariations.length && (
              <BestPrice>
                <span>a partir de </span>
                <strong>
                  {formatPrice(
                    getLowestPriceOfProductVariations(productVariations),
                  )}
                </strong>
              </BestPrice>
            )}

            {allowPromotionTime && (
              <PromotionTimeContainer>
                <PromotionTime>
                  <span>Termina em:</span>
                  <div>
                    <MdTimer size={18} color="#ff0000" />
                    <Countdown
                      date={new Date(promotionTime)}
                      renderer={countdownRender}
                    />
                  </div>
                </PromotionTime>

                {showBenefitClub && (
                  <BenefitClub>
                    <span>OFERTA</span>
                    <div>
                      <strong>CB</strong>
                    </div>
                  </BenefitClub>
                )}
              </PromotionTimeContainer>
            )}
          </div>
        </div>
      </LazyLoad>
    </Product>
  );
};

export default Catalogo;
