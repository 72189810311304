import React from 'react';

import {
  MdPlace,
  MdPhone,
  MdMessage,
  MdHelp,
  MdInfo,
  MdLanguage,
  MdSmartphone,
  MdAccountBalance,
  MdBookmark,
  MdDateRange,
  MdCameraAlt,
  MdCheckCircle,
  MdFileDownload,
  MdQuestionAnswer,
  MdLightbulbOutline,
  MdLock,
  MdSecurity,
  MdShare,
  MdStar,
  MdStore,
  MdTimer,
  MdWidgets,
  MdHome,
  MdArrowBack,
  MdArrowDownward,
  MdArrowForward,
  MdArrowUpward,
  MdLocalDining,
  MdDirectionsBus,
  MdDirectionsCar,
  MdDirectionsBike,
  MdDirectionsRun,
  MdMotorcycle,
  MdLocalBar,
  MdLocalDrink,
  MdCake,
  MdShoppingCart,
} from 'react-icons/md';
import {
  FaAppleAlt,
  FaBriefcaseMedical,
  FaBuffer,
  FaBuilding,
  FaChartBar,
  FaFacebook,
  FaGift,
  FaHeart,
  FaInstagram,
  FaLinkedin,
  FaPercentage,
  FaQrcode,
  FaTag,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';

export default function getIconByName(iconName) {
  switch (iconName) {
    case 'whatsapp':
      return <FaWhatsapp size={22} color="#fff" />;
    case 'instagram':
      return <FaInstagram size={22} color="#fff" />;
    case 'linkedin':
      return <FaLinkedin size={22} color="#fff" />;
    case 'facebook':
      return <FaFacebook size={22} color="#fff" />;
    case 'twitter':
      return <FaTwitter size={22} color="#fff" />;
    case 'youtube':
      return <FaYoutube size={22} color="#fff" />;
    case 'map-marker':
      return <MdPlace size={22} color="#fff" />;
    case 'phone':
      return <MdPhone size={22} color="#fff" />;
    case 'message-bulleted':
      return <MdMessage size={22} color="#fff" />;
    case 'help-circle':
      return <MdHelp size={22} color="#fff" />;
    case 'information':
      return <MdInfo size={22} color="#fff" />;
    case 'web':
      return <MdLanguage size={22} color="#fff" />;
    case 'cellphone':
      return <MdSmartphone size={22} color="#fff" />;
    case 'bank':
      return <MdAccountBalance size={22} color="#fff" />;
    case 'bookmark':
      return <MdBookmark size={22} color="#fff" />;
    case 'buffer':
      return <FaBuffer size={22} color="#fff" />;
    case 'calendar-month':
      return <MdDateRange size={22} color="#fff" />;
    case 'camera':
      return <MdCameraAlt size={22} color="#fff" />;
    case 'chart-bar':
      return <FaChartBar size={22} color="#fff" />;
    case 'check-circle':
      return <MdCheckCircle size={22} color="#fff" />;
    case 'download':
      return <MdFileDownload size={22} color="#fff" />;
    case 'gift':
      return <FaGift size={22} color="#fff" />;
    case 'forum':
      return <MdQuestionAnswer size={22} color="#fff" />;
    case 'heart':
      return <FaHeart size={22} color="#fff" />;
    case 'lightbulb-on':
      return <MdLightbulbOutline size={22} color="#fff" />;
    case 'lock':
      return <MdLock size={22} color="#fff" />;
    case 'qrcode':
      return <FaQrcode size={22} color="#fff" />;
    case 'sale':
      return <FaPercentage size={22} color="#fff" />;
    case 'security':
      return <MdSecurity size={22} color="#fff" />;
    case 'share-variant':
      return <MdShare size={22} color="#fff" />;
    case 'star':
      return <MdStar size={22} color="#fff" />;
    case 'store':
      return <MdStore size={22} color="#fff" />;
    case 'tag':
      return <FaTag size={22} color="#fff" />;
    case 'timer':
      return <MdTimer size={22} color="#fff" />;
    case 'widgets':
      return <MdWidgets size={22} color="#fff" />;
    case 'home':
      return <MdHome size={22} color="#fff" />;
    case 'arrow-left':
      return <MdArrowBack size={22} color="#fff" />;
    case 'arrow-down':
      return <MdArrowDownward size={22} color="#fff" />;
    case 'arrow-right':
      return <MdArrowForward size={22} color="#fff" />;
    case 'arrow-up':
      return <MdArrowUpward size={22} color="#fff" />;
    case 'food':
      return <MdLocalDining size={22} color="#fff" />;
    case 'food-fork-drink':
      return <MdLocalDining size={22} color="#fff" />;
    case 'bus':
      return <MdDirectionsBus size={22} color="#fff" />;
    case 'car':
      return <MdDirectionsCar size={22} color="#fff" />;
    case 'bike':
      return <MdDirectionsBike size={22} color="#fff" />;
    case 'run':
      return <MdDirectionsRun size={22} color="#fff" />;
    case 'motorbike':
      return <MdMotorcycle size={22} color="#fff" />;
    case 'beer':
      return <MdLocalBar size={22} color="#fff" />;
    case 'cupcake':
      return <MdCake size={22} color="#fff" />;
    case 'cup':
      return <MdLocalDrink size={22} color="#fff" />;
    case 'medical-bag':
      return <FaBriefcaseMedical size={22} color="#fff" />;
    case 'office-building':
      return <FaBuilding size={22} color="#fff" />;
    case 'food-apple':
      return <FaAppleAlt size={22} color="#fff" />;
    case 'no-icon':
      return '';
    default:
      return <MdShoppingCart size={22} color="#fff" />;
  }
}
