import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  padding: 20px;
`;

export const Banner = styled.a`
  color: #fff;
  width: 100%;
  height: 26px;
  max-width: 323px;
  position: fixed;
  bottom: 10px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0 11px;
  border-radius: 8px;

  p {
    font-size: 11px;
    font-weight: 600;
  }
`;
