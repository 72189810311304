import styled from 'styled-components/macro';

export const Container = styled.div`
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  > div {
    background: #fff;
    width: 100%;
    color: #4f4f4f;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  /* Custom style slider */
  .rec-arrow-left {
    color: #2690fb !important;
    position: absolute;
    left: -13px;
    background-color: unset !important;
    border: none !important;
    box-shadow: none !important;
  }

  .rec-arrow-right {
    color: #2690fb !important;
    position: absolute;
    right: -13px;
    background-color: unset !important;
    border: none !important;
    box-shadow: none !important;
  }

  .rec-pagination {
    justify-content: center;

    .rec-dot {
      height: 7px;
      width: 25px;
      box-shadow: none;
      border-radius: 10px;
      border: 1px solid #c9c5c5;

      &.rec-dot_active {
        background: #ff5c00;
        border: 1px solid #ff5c00;
      }
    }
  }
`;

export const ProductModal = styled.div`
  > span {
    display: block;
    margin-bottom: 19px;
    margin-top: 19px;
    font-size: 18px;
    padding: 0 21px;
    text-align: left;
    font-weight: 600;
    color: #363636;
  }

  .description {
    white-space: pre-line;
    width: 100%;
    padding: 0 21px;
    text-align: left;
    margin-bottom: 19px;
  }

  .variations {
    text-align: left;
    padding: 0 21px;

    div {
      margin-bottom: 8px;
      span {
        margin-right: 10px;
      }
    }
  }

  .image {
    width: 100%;
    height: auto;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }

  .price {
    padding: 0 21px;
    text-align: left;
    font-size: 18px;
    color: #363636;

    &-value {
      display: block;
      margin-top: 19px;
      &.discount {
        text-decoration: line-through;
        font-weight: 500;
        font-size: 14px;
        color: #d12727;
      }
    }

    &-discount {
      display: block;
      margin-top: 5px;
      font-weight: 600;
    }
  }

  .rec-slider-container {
    margin: 0;
  }

  .rec-dot {
    width: 7px !important;
  }

  .footer-close {
    position: absolute;
    left: 13px;
    top: 13px;

    button {
      border: none;
      width: 39px;
      height: 39px;
      border-radius: 50%;
      background: #ececec;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ProductModalTag = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  padding: 3px 11px;
  border-radius: 8px;
  color: #fff;
  font-size: 7px;
  font-weight: 600;
`;

export const PromotionTime = styled.div`
  margin-top: 10px;
  text-align: left;

  span {
    color: #ff0000;
    font-size: 13px;
    font-weight: 500;
  }

  > div {
    margin-top: 5px;
    display: flex;
    align-items: center;

    strong {
      color: #ff0000;
      margin-left: 5px;
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

export const ProductModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 90px;
  padding: 0 21px;

  > div {
    display: flex;
    align-items: center;
  }

  .whatsapp_button {
    width: 33px;
    height: 33px;
    background: #25d366;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pix_button {
    border-radius: 18px;
    background: #5bff96;
    color: #363636;
    width: 62px;
    height: 33px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    margin-right: 23px;
  }

  .buy_button {
    border-radius: 18px;
    padding: 7px 15px;
    background: #ff5c00;
    color: #fff;
    display: flex;
    align-items: center;
    border: none;

    span {
      font-size: 13px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
`;

export const BenefitClub = styled.div`
  width: 133px;
  height: 31px;
  padding: 4px 7px;
  background: #ff0000;
  border-radius: 5.5px;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
  color: #fff;
  margin: 10px 21px 0 21px;
`;

export const ModalPix = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: -10px;
  z-index: 9999;

  div {
    width: 86%;
    height: 130px;
    margin: 0 auto;
    background: #fff;
    color: #4f4f4f;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    top: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    span {
      display: block;

      & + span {
        margin-top: 15px;
      }
    }
  }
`;
