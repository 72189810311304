import React, { useEffect, useState } from 'react';
import { MdSave } from 'react-icons/md';

import { useLocation, useParams } from 'react-router-dom';
import { AvatarHeader, Container, Modal } from './styles';
import logo from '../../assets/logo.png';
import playstore from '../../assets/playstore.png';
import appstore from '../../assets/appstore.png';
import api from '../../services/api';

const Header = ({ mini }) => {
  const [activeModal, setActiveModal] = useState(false);
  const [logoCard, setLogoCard] = useState(null);
  const [loading, setLoading] = useState(true);

  const { nickname } = useParams();

  function isQRCard() {
    const { pathname } = window.location;
    const module = pathname.split('/')[3];

    if (module === 'qrcard') {
      return true;
    }

    return false;
  }

  async function getCardLogo() {
    try {
      const response = await api.get(`/qr-codes/${nickname}`);
      setLogoCard(response.data.user_avatar);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCardLogo();
  }, []);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const isEmbeded = query.get('isEmbeded') === 'true';

  return (
    <>
      {!isEmbeded && (
        <>
          <Container mini={mini}>
            <div>
              {!loading && !logoCard && (
                <a
                  href="https://qrcartaoweb.com.br/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img src={logo} alt="logo" />
                </a>
              )}

              {!loading && logoCard && <AvatarHeader logo={logoCard} />}
            </div>

            {isQRCard() && (
              <button type="button" onClick={() => setActiveModal(true)}>
                <MdSave size={30} color="#fff" />
              </button>
            )}
          </Container>

          {activeModal && (
            <Modal onClick={() => setActiveModal(false)}>
              <div>
                <span>
                  Para salvar o contato, baixe o aplicativo e faça seu cadastro.
                </span>

                <div>
                  <a
                    href="https://play.google.com/store/apps/details?id=solucoesdaweb.qrcartao&hl=pt"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={playstore} alt="playstore" />
                  </a>

                  <a
                    href="https://apps.apple.com/us/app/qr-cart%C3%A3o/id1477714637?l=pt&ls=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appstore} alt="playstore" />
                  </a>
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default Header;
