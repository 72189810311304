import React from 'react';
import { Switch, Route } from 'react-router-dom';

import SelectModule from '../pages/SelectModule';
import QRWifi from '../pages/QRWifi';
import QRMessage from '../pages/QRMessage';
import QRDiscount from '../pages/QRDiscount';
import QRFlyer from '../pages/QRFlyer';
import QRPix from '../pages/QRPix';
import QRGeneric from '../pages/QRGeneric';
import QRCard from '../pages/QRCard';
import QRAds from '../pages/QRAds';
import { ShowProduct } from '../pages/ShowProduct';

const Routes = () => (
  <Switch>
    <Route
      exact
      path="/"
      component={() => {
        window.location.href = 'https://qrcartao.com.br';
        return null;
      }}
    />
    <Route exact path="/user/:nickname" component={SelectModule} />
    <Route path="/user/:nickname/qrwifi/:id" component={QRWifi} />
    <Route path="/user/:nickname/qrmessage/:id" component={QRMessage} />
    <Route path="/user/:nickname/qrdiscount/:id" component={QRDiscount} />
    <Route path="/user/:nickname/qrflyer/:id" component={QRFlyer} />
    <Route path="/user/:nickname/qrpix/:id" component={QRPix} />
    <Route path="/user/:nickname/qrgeneric/:id" component={QRGeneric} />
    <Route path="/user/:nickname/qrcard/:id" component={QRCard} />
    <Route exact path="/user/:nickname/qrads/:id" component={QRAds} />
    <Route
      exact
      path="/user/:nickname/qrads/produto/:product_id"
      component={ShowProduct}
    />
  </Switch>
);

export default Routes;
