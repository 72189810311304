import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  color: #fff;
  background: #fff;
  border-radius: 25px;
  position: relative;
  top: -25px;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 30px;

  ${({ isEmbeded }) =>
    isEmbeded &&
    css`
      margin-top: 50px !important;

      .header-module-content {
        a {
          display: none;
        }
      }
    `}
`;

export const Content = styled.div`
  margin-top: 15px;
  height: 100%;
`;

export const CategorySelect = styled.div`
  > span {
    color: #606060;
    font-weight: 500;
    margin-bottom: 5px;
    display: block;
    margin-left: 15px;
    font-size: 13px;
  }

  > div {
    width: 100%;
    height: 41px;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border-radius: 15px;

    select {
      width: 90%;
      margin: 0 15px;
      border: none;
      background: none;
      color: #606060;
      font-weight: 600;
      text-transform: capitalize;
    }
  }
`;

export const SearchProduct = styled.div`
  background: #f0f0f0;
  width: 100%;
  display: flex;
  height: 34px;
  border-radius: 20px;

  input {
    flex: 1;
    background: none;
    border: none;
    color: #606060;
  }

  button {
    padding: 0 9px;
    margin-right: 10px;
    border: none;
    border-radius: 20px;

    svg {
      margin-top: 5px;
    }
  }
`;

export const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Filters = styled.div`
  padding: 0 25px;
`;

export const ProductContainer = styled.div``;

ProductContainer.Category = styled.span`
  text-align: center;
  background: #ff5c00;
  margin-top: 20px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-transform: capitalize;
`;

export const ScrollTop = styled.div`
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;

  > div {
    width: 40px;
    height: 40px;
    background: #ff5c00;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const HeaderModuleContent = styled.div`
  height: 56px;
  background: #f0f0f0;
  color: #363636;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  a {
    border: none;
    padding: 4px;
    position: absolute;
    left: 5px;
    background: none;
  }

  > div {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 10px;
      font-size: 16px;
    }
  }
`;

export const ProductListCatalogo = styled.div`
  padding: 0 25px;
`;

export const ProductCatalogo = styled.div`
  & + div {
    border-top: 1px solid #c4c4c4;
  }

  > div {
    display: flex;
    justify-content: space-between;
    padding: 17px 0;
  }

  .content {
    max-width: 205px;
    display: flex;
    flex-direction: column;

    > strong {
      font-size: 14px;
      color: #363636;
    }

    > p {
      margin-top: 5px;
      margin-bottom: 16px;
      font-size: 12px;
      color: #363636;
      line-height: 15.85px;
    }

    > span {
      font-weight: 500;
      font-size: 14px;
      color: #363636;
    }

    .discount {
      display: flex;
      align-items: center;

      strong {
        font-weight: 500;
        color: #17901c;
        font-size: 14px;
        margin-right: 10px;
      }

      span {
        text-decoration: line-through;
        font-size: 13px;
        color: #9d9d9d;
      }
    }
  }

  img {
    width: 97px;
    height: 97px;
    border-radius: 10px;
    object-fit: cover;
  }
`;

export const BestPrice = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  span {
    margin-bottom: 6px;
    font-size: 13px;
    color: #646464;
  }

  strong {
    font-size: 13px;
    color: #363636;
  }
`;

export const ShareProfile = styled.button`
  position: absolute;
  background: none;
  border: none;
  top: 25px;
  right: 25px;

  background: #ff5c00;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

export const AddContact = styled.button`
  position: absolute;
  background: none;
  border: none;
  top: 25px;
  left: 25px;
  background: #ff5c00;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: fadeIn 0.5s ease-in-out;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

export const ModalAddContact = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 315px;
    margin: 0 auto;
    background: #d9d9d9;
    color: #363636;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: 10px;
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      line-height: 22px;
    }

    button {
      background: #61c384;
      color: #000;
      font-size: 13px;
      border-radius: 8px;
      padding: 0 20px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      margin-top: 10px;
    }

    span {
      padding: 7px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
`;

export const PromotionTime = styled.div`
  margin-top: 10px;
  text-align: left;

  span {
    color: #ff0000;
    font-size: 13px;
    font-weight: 500;
  }

  > div {
    margin-top: 5px;
    display: flex;
    align-items: center;

    strong {
      color: #ff0000;
      margin-left: 5px;
      font-weight: 600;
      font-size: 12px;
    }
  }
`;

export const BenefitClub = styled.div`
  width: 133px;
  height: 31px;
  padding: 4px 7px;
  background: #ff0000;
  border-radius: 5.5px;
  font-size: 9px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  text-align: left;
  color: #fff;
  margin: 10px 21px 0 21px;
`;
