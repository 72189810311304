import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  color: #fff;
  background: #fff;
  border-radius: 25px;
  position: relative;
  top: -25px;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;

  .header {
    height: 60px;
    background: #f0f0f0;
    color: #4f4f4f;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      margin-left: 10px;
    }
  }

  ${({ isEmbeded }) =>
    isEmbeded &&
    css`
      margin-top: 50px !important;

      .header-module-content {
        a {
          display: none;
        }
      }
    `}
`;

export const HeaderModuleContent = styled.div`
  height: 56px;
  background: #f0f0f0;
  color: #363636;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  a {
    border: none;
    padding: 4px;
    position: absolute;
    left: 5px;
    background: none;
  }

  > div {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 10px;
      font-size: 16px;
    }
  }
`;

export const Content = styled.div`
  padding: 0 30px 70px 30px;
  margin-top: 20px;
`;

export const ContentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 25px;

  span {
    color: #4f4f4f;
    max-width: 177px;
    width: 100%;
    text-align: center;
    margin: 10px auto 0 auto;
  }
`;

export const ContentForm = styled.div`
  > span {
    color: #4f4f4f;
    margin-top: 30px;
    margin-bottom: 5px;
    margin-left: 30px;
    display: block;
    font-size: 14px;
    font-weight: 600;

    &:first-child {
      text-align: center;
      margin-left: 0px;
    }
  }

  > div {
    background: #f0f0f0;
    min-height: 45px;
    padding: 10px;
    color: #4f4f4f;
    border-radius: 20px;
    margin-bottom: 25px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.percentage {
      width: 90px;
      margin: 0 auto !important;
    }
  }
`;
export const BenefitClubAds = styled.div`
  display: flex;
  flex-direction: column;

  strong {
    background: #ff5c00;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 15px;
    font-size: 20px;
    text-transform: uppercase;
  }

  p {
    font-size: 13px;
    color: #363636;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
  }

  a {
    margin: 0 auto;
    width: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    background: #25d366;
    border-radius: 10px;
    border: 0;
    font-weight: 700;
    color: #fff;
  }
`;
