import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  height: 140px;
  padding: 0 20px;
  position: relative;
  top: 0;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 25px;

    img {
      width: 88px;
      max-width: 60px;
    }

    svg {
      cursor: pointer;
    }

    button {
      background: none;
      border: none;
    }
  }
`;

export const Modal = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;
  text-align: center;

  > div {
    width: 86%;
    height: 300px;
    margin: 0 auto;
    background: #fff;
    color: #4f4f4f;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    top: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    div {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 10px 20px 20px 20px;
      a {
        margin-bottom: 15px;
      }
    }

    span {
      display: block;
      margin-bottom: 30px;
    }
  }
`;

export const AvatarHeader = styled.div`
  width: 100px;
  height: 100px;
  background-image: url(${props => props.logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
`;
