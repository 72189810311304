import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  color: #fff;
  background: #fff;
  border-radius: 25px;
  position: relative;
  top: -25px;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;

  .header {
    height: 60px;
    background: #f0f0f0;
    color: #4f4f4f;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      margin-left: 10px;
    }
  }

  ${({ isEmbeded }) =>
    isEmbeded &&
    css`
      margin-top: 50px !important;

      .header-module-content {
        a {
          display: none;
        }
      }
    `}
`;

export const Content = styled.div`
  padding: 0 30px 70px 30px;
  margin-top: 30px;
  height: 100%;
  border-radius: 25px;
`;

export const HeaderModuleContent = styled.div`
  height: 56px;
  background: #f0f0f0;
  color: #363636;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  a {
    border: none;
    padding: 4px;
    position: absolute;
    left: 5px;
    background: none;
  }

  > div {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 10px;
      font-size: 16px;
    }
  }
`;

export const ContentForm = styled.div`
  > span {
    color: #4f4f4f;
    margin-left: 30px;
    margin-bottom: 5px;
    display: block;
    font-size: 14px;
    font-weight: 600;
  }

  > div {
    background: #f0f0f0;
    height: 100%;
    padding: 10px;
    color: #4f4f4f;
    border-radius: 20px;
    margin-bottom: 25px;
    font-size: 14px;
    white-space: pre-line;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      width: 100%;
    }
  }
`;
