import React, { useState, useEffect } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import {
  BenefitClubAds,
  Container,
  Content,
  ContentForm,
  ContentInfo,
  HeaderModuleContent,
} from './styles';
import api from '../../services/api';
import Loading from '../../components/Loading';
import NotFound from '../../components/NotFound';
import iconDiscountSmall from '../../assets/icon-discount-small.png';
import iconDiscount from '../../assets/icon-discount.png';

const QRDiscount = ({ moduleId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [discount, setDiscount] = useState(null);
  const { id, nickname } = useParams();

  const cardId = id || moduleId;

  useEffect(() => {
    try {
      api.get(`/qr-discounts/p/${cardId}`).then(response => {
        setDiscount(response.data);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const isEmbeded = query.get('isEmbeded') === 'true';

  console.log({ discount });
  return (
    <>
      <Header nickname={nickname} moduleId={cardId} />

      <Container isEmbeded={isEmbeded}>
        <HeaderModuleContent className="header-module-content">
          <Link to={`/user/${nickname}/?open_modules=true`} type="button">
            <MdChevronLeft size={40} color="#4F4F4F" />
          </Link>
          <div>
            <img src={iconDiscountSmall} alt="" />
            <h3>QR Desconto</h3>
          </div>
        </HeaderModuleContent>

        {isLoading && <Loading />}

        {!isLoading && discount && (
          <Content>
            <ContentInfo>
              <img src={iconDiscount} alt="" />
              <span>Para ter acesso ao desconto, confira as condições</span>
            </ContentInfo>

            <ContentForm>
              <span>Percentual:</span>
              <div className="percentage">{`${discount.percentage}%`}</div>

              <span>Descrição</span>
              <div>{discount.description}</div>
            </ContentForm>

            {discount.show_benefit_club_ads && (
              <BenefitClubAds>
                <strong>CLUBE DE BENEFÍCIOS</strong>
                <p>
                  Faça parte do nosso Clube de Benefícios para ter acesso a
                  ofertas exclusivas!
                </p>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://qrcartaoweb.com.br/user/cadastroqrbio"
                >
                  PARTICIPAR
                </a>
              </BenefitClubAds>
            )}
          </Content>
        )}

        {!isLoading && !discount && (
          <NotFound message="QR Desconto não encontrado" />
        )}
      </Container>
    </>
  );
};

export default QRDiscount;
