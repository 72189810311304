import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  color: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: relative;
  top: -25px;
  max-width: 800px;
  width: 100%;
  margin: 50px auto 25px auto;

  .assinatura {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  > span {
    margin-top: 20px;
    margin-bottom: 20px;
    color: #4f4f4f;
    display: block;
    text-align: center;
    font-weight: 600;
  }
`;

export const ListModules = styled.ul`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SocialMedia = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;

  li {
    margin-right: 12px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }

    img {
      width: 43px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${props => (props.moduleDarkColor ? '#000' : '#fff')};

  > div {
    width: 126px;
    height: 126px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  strong {
    text-align: center;
    font-size: 16px;
    margin-top: 24px;
  }

  > p {
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    margin: 12px auto 0 auto;
    line-height: 13.41px;
    white-space: pre-line;
    word-break: break-word;
  }
`;

export const ShareProfile = styled.button`
  position: absolute;
  background: none;
  border: none;
  top: 0;
  right: 25px;

  background: #ff5c00;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

export const AddContact = styled.button`
  position: absolute;
  background: none;
  border: none;
  top: 0;
  left: 25px;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: fadeIn 0.5s ease-in-out;

  background: #ff5c00;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;

export const AddContactAlert = styled.div`
  display: ${props => (props.showAddContactAlert ? 'flex' : 'none')};
  justify-content: space-around;
  position: fixed;
  padding: 13px;
  background: #fff;
  border-radius: 20px;
  width: 338px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: ${props => (props.showAddContactAlert ? 999 : 0)};
  bottom: 10px;
  opacity: ${props => (props.showAddContactAlert ? 1 : 0)};

  @keyframes addContactAlertanimationIn {
    0% {
      opacity: 0;
      bottom: -42.5px;
    }
    20% {
      opacity: 0.5;
      bottom: -25px;
    }
    80% {
      opacity: 0.7;
      bottom: -7.5px;
    }
    100% {
      opacity: 1;
      bottom: 10px;
    }
  }

  @keyframes addContactAlertanimationOut {
    0% {
      opacity: 1;
      bottom: 10px;
    }
    20% {
      opacity: 0.7;
      bottom: -7.5px;
    }
    80% {
      opacity: 0.5;
      bottom: -25px;
    }
    100% {
      opacity: 0;
      bottom: -42.5px;
      display: none;
    }
  }

  ${({ showAddContactAlert }) =>
    showAddContactAlert &&
    css`
      animation: addContactAlertanimationIn 0.5s;
    `}

  ${({ showAddContactAlert }) =>
    !showAddContactAlert &&
    css`
      animation: addContactAlertanimationOut 0.2s;
    `}

  p {
    color: #000;
    font-weight: 600;
    width: 260px;
    font-size: 13px;
    text-align: center;
    line-height: 15.85px;
  }

  strong {
    font-weight: 700;
  }
`;

export const ModalAddContact = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 315px;
    margin: 0 auto;
    background: #d9d9d9;
    color: #363636;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: 10px;
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      line-height: 22px;
    }

    button {
      background: #61c384;
      color: #000;
      font-size: 13px;
      border-radius: 8px;
      padding: 0 20px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      margin-top: 10px;
    }

    span {
      padding: 7px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }
`;

export const Partner = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 8px;
  font-family: Montserrat-Regular;
  margin: 20px 0px 20px;

  span {
    color: #fff;
    font-weight: 600;
    width: 260px;
    margin: 15px 0px 5px;
    font-size: 15px;
    text-align: center;
    line-height: 20.85px;
  }

  img {
    width: 250px;
    max-height: 120px;
    object-fit: contain;
    border-radius: 15px;
    margin-top: 10px;
  }
`;
