import React, { useState, useEffect } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { Link, useLocation, useParams } from 'react-router-dom';
import Carousel from 'react-elastic-carousel';

import Header from '../../components/Header';
import { Container, Content, HeaderModuleContent } from './styles';
import api from '../../services/api';
import Loading from '../../components/Loading';
import NotFound from '../../components/NotFound';

const QRFlyer = ({ moduleId }) => {
  const [flyer, setFlyer] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { id, nickname } = useParams();

  const cardId = id || moduleId;

  useEffect(() => {
    try {
      api.get(`/qr-flyers/p/${cardId}`).then(response => {
        setFlyer(response.data);
        setIsLoading(false);
      });
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();

  const isEmbeded = query.get('isEmbeded') === 'true';

  return (
    <>
      <Header nickname={nickname} moduleId={cardId} />

      <Container isEmbeded={isEmbeded}>
        <HeaderModuleContent className="header-module-content">
          <Link to={`/user/${nickname}/?open_modules=true`} type="button">
            <MdChevronLeft size={40} color="#4F4F4F" />
          </Link>
          <div>
            <h3>{flyer && flyer.qrname ? flyer.qrname : 'QR Panfleto'}</h3>
          </div>
        </HeaderModuleContent>

        {isLoading && <Loading />}

        {!isLoading && flyer && (
          <Content>
            <Carousel itemsToScroll={1} itemsToShow={1} showArrows={false}>
              {flyer.flyer_images.map(flyerImage => (
                <div className="sliderItem" key={flyerImage.id}>
                  <img src={flyerImage.image_url} alt="back" />
                </div>
              ))}
            </Carousel>
          </Content>
        )}

        {!isLoading && !flyer && (
          <NotFound message="QR Panfleto não encontrado" />
        )}
      </Container>
    </>
  );
};

export default QRFlyer;
