import React from 'react';
import { MdWarning } from 'react-icons/md';
import { Container } from './styles';

const ExceededViews = () => (
  <Container>
    <div>
      <MdWarning size="100" color="#EECE13" />
      <strong>Perfil indisponível no momento</strong>
      <p>Verifique o status do perfil no aplicativo</p>
    </div>
  </Container>
);

export default ExceededViews;
