import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: 100%;
  color: #fff;
  background: #fff;
  border-radius: 25px;
  position: relative;
  top: -25px;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;

  ${({ isEmbeded }) =>
    isEmbeded &&
    css`
      margin-top: 50px !important;

      .header-module-content {
        a {
          display: none;
        }
      }
    `}
`;

export const HeaderModuleContent = styled.div`
  height: 56px;
  background: #f0f0f0;
  color: #363636;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  a {
    border: none;
    padding: 4px;
    position: absolute;
    left: 5px;
    background: none;
  }

  > div {
    display: flex;
    align-items: center;

    h3 {
      margin-left: 10px;
      font-size: 16px;
    }
  }
`;

export const Content = styled.div`
  padding: 0 30px 70px 30px;
  margin-top: 10px;

  > button {
    background: #ff5c00;
    border-radius: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 41px;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    margin-top: 30px;
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 25px;

  span {
    color: #4f4f4f;
    max-width: 177px;
    width: 100%;
    text-align: center;
    margin: 10px auto 0 auto;
  }
`;

export const ContentForm = styled.div`
  > span {
    color: #363636;
    margin-bottom: 3px;
    margin-left: 30px;
    display: block;
    font-size: 14px;
    font-weight: 600;
  }

  > div {
    height: 41px;
    border-radius: 80px;
    background: #ececec;
    padding: 10px 30px;
    color: #4f4f4f;
    margin-bottom: 20px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;

    button {
      border: none;
    }
  }
`;

export const Modal = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 9999;

  div {
    width: 86%;
    height: 130px;
    margin: 0 auto;
    background: #fff;
    color: #4f4f4f;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    top: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;
