/* eslint-disable prettier/prettier */
import styled, { css, createGlobalStyle } from 'styled-components';

const fontSize = '14px';
const cardHeight = (window.innerWidth / 9) * 16;

export default createGlobalStyle`
  body {
    background: #4f4f4f !important;
  }
`;

export const Container = styled.div`
  height: 100%;
  color: #fff;
  background: #4f4f4f;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: relative;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
`;

// Card
export const Card = styled.div`
  max-height: 680px;
  height: ${`${cardHeight}px`};
  margin: 0px 5px 0 5px;
  background: ${props => props.backgroundColor};
  border-radius: 20px;
  padding: 25px 15px 10px 15px;
  text-align: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${props => props.fontColor};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  ${({ background }) =>
    background &&
    css`
      background-image: url(${background});
    `}
`;

// Card Logo
Card.Logo = styled.div`
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  margin-bottom: 30px;

  ${({ logo, showLogo }) =>
    logo &&
    showLogo &&
    css`
      background-image: url(${logo});
      border-radius: 6px;
    `}
`;

// Card Content
Card.Content = styled.div`
  border-radius: 8px;
  margin-bottom: 25px;
  height: 330px;
  padding: 20px 30px;
  overflow: hidden;

  ${({ showBackgroundInfo }) =>
    showBackgroundInfo &&
    css`
      background: rgb(0, 0, 0, 0.4);
    `}

  .line {
    font-weight: 600;
    margin-bottom: 12px;

    h3 {
      font-size: 15px;
      margin-bottom: 4px;
    }

    span {
      font-size: 15px;
    }

    &:first-child {
      h3 {
        font-size: 17px;
      }
    }
  }

  .andress {
    font-weight: 600;
    width: 100%;
    line-height: 17px;
    font-size: ${fontSize};
    margin-bottom: 12px;
  }

  span {
    display: block;
    font-weight: 600;
    font-size: 14px;

      margin-bottom: 12px;
  }
`;

// Card Links
Card.Links = styled.div`
  font-size: ${fontSize};
  background: rgb(0, 0, 0, 0.4);
  border-radius: 8px;
  padding: 10px 10px 0 10px;
  display: inline-block;
  margin: 0 auto;

  span {
    display: block;
    font-weight: 600;
    margin-bottom: 20px;
  }

  a {
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
  }
`;

Card.LogoCompany = styled.div`
  width: 104px;
  margin: 25px auto 10px auto;

  img {
    width: 100%;
  }
`;

export const Header = styled.div`
  padding: 7px;

  a {
    margin: 0 10px;
  }
`;
